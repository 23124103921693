.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.leaflet-container {
  min-width: 500px;
  min-height: 500px;
  z-index: 1;
}


@media (max-width: 899px) {
  .leaflet-container {
    min-width: 300px;
    min-height: 300px;
    z-index: 1;
  }
}




body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4; /* Fondo gris claro */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: normal;
    height: 100vh;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

header {
    background-color: white; /* Fondo negro para el encabezado */
    color: black; /* Texto blanco */
    padding: 20px 0;
}

header h1 {
    font-size: 24px;
}

nav ul {
    list-style: none;
}

nav ul li {
    display: inline;
    margin-right: 20px;
}

nav ul li a {
    color: #fff;
    text-decoration: none;
}

main {
    padding: 20px 0;
}

article {
    background-color: #fff; /* Fondo blanco para los artículos */
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra suave */
}

article h2 {
    color: #333; /* Título negro */
}

.btn {
    display: inline-block;
    background-color: #333; /* Botón negro */
    color: #fff;
    padding: 8px 16px;
    text-decoration: none;
    border-radius: 4px;
}

.btn:hover {
    background-color: #555; /* Botón negro oscuro al pasar el ratón */
}

footer {
    background-color: #333; /* Fondo negro para el pie de página */
    color: #fff;
    padding: 20px 0;
    text-align: center;
}